import React from 'react'  // Build dependency, even if not used
import { useState, useRef, useEffect } from "react"
import { createRoot } from "react-dom/client"
import { humanizeTime } from "./utils"


function Timer({ initialTimeLeft }) {
    const [timeLeft, setTimeLeft] = useState(initialTimeLeft)
    const timerInterval = useRef()

    // Changing time
    useEffect(() => {
        timerInterval.current = setInterval(() => {
            setTimeLeft(time => time - 1000)
        }, 1000)
        return () => clearInterval(timerInterval.current)
    }, [])

    let message = humanizeTime(timeLeft)
    if (timeLeft <= 0) {
        clearInterval(timerInterval.current)
        message = 'Already ended!'
    }
    return (
        <span>{message}</span>
    )
}

const timerContainers = document.getElementById('main').getElementsByClassName('timer-container')
let activeTimers = 0

for (const container of timerContainers) {
    const currentDatetime = container.getAttribute('current-time')
    const endDatetime = container.getAttribute('end-time')
    const timeLeft = Date.parse(endDatetime) - Date.parse(currentDatetime)
    const root = createRoot(container);
    root.render(<Timer initialTimeLeft={timeLeft} />);

    if (timeLeft >= 0) {
        activeTimers += 1
    }
}

console.log('Active timers: ' + activeTimers)
