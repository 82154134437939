// Convert time from milis to days, hours, minutes, seconds string
export function humanizeTime(timeMilis) {
    const msInDay = 86400000
    const msInHour = 3600000
    const msInMinute = 60000
    const msInSecond = 1000

    let remainingTime = timeMilis
    const days = Math.floor(remainingTime / msInDay)
    remainingTime -= days * msInDay
    const hours = Math.floor(remainingTime / msInHour)
    remainingTime -= hours * msInHour
    const minutes = Math.floor(remainingTime / msInMinute)
    remainingTime -= minutes * msInMinute
    const seconds = Math.floor(remainingTime / msInSecond)

    if (days > 0) {
        return days + ' days ' + hours + ' hours'
    } else if (hours > 0) {
        return hours + ' hours ' + minutes + ' min'
    } else {
        return minutes + ' min ' + seconds + ' sec'
    }
}


export function humanizeDatetime(datetime, currentDatetime) {
    const timeString = datetime.toUTCString()
    const msInDay = 86_400_000
    const daysSinceDatetime = Math.ceil((currentDatetime.getTime() - datetime.getTime()) / msInDay)

    const dayMonth = timeString.slice(5, 11)
    const year = timeString.slice(12, 16)
    const hoursMinutes = timeString.slice(17, 22)

    // Return hours:minutes as default for today datetimes
    let resultDatetimeString = hoursMinutes

    // For yesterday datetime
    if (datetime.getDate() == currentDatetime.getDate() - 1) {
        resultDatetimeString = 'yesterday ' + hoursMinutes
    } // For dates older than yesterday but not older than a year
    else if (daysSinceDatetime > 1 && daysSinceDatetime <= 365) {
        resultDatetimeString = dayMonth + ' ' + hoursMinutes
    } // For dates older than a year
    else if (daysSinceDatetime > 365) {
        resultDatetimeString = dayMonth + ' ' + year + ' ' + hoursMinutes
    }
    return resultDatetimeString
}